.footer-container {
  height: 15rem;
  background-color: #292929;
}

.footer-content {
  margin: auto auto;
  height: 100%;
  max-width: 50%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
}
.footer-elements-wrapper {

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0 auto;
  }

  .footer-element {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 768px) {
      flex-direction: row;
    }

    @media (max-width: 1320px) and (min-width: 769px) {
      font-size: 1rem;
    }
  }
}
.footer-text-secondary {
  color: #a5a5a5;
  font-size: 1.15em;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}