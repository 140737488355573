.contact-container {
  @media (min-width: 768px) {
    height: 75vh;
  }
}

.contact-tabs {
  font-size: 1.2rem;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    font-size: 1rem;
    max-width: 95%;
  }

  @media (max-width: 1320px) and (min-width: 769px) {
    font-size: 0.8rem;
  }

  @media (max-width: 1528px) and (min-width: 1321px) {
    font-size: 1rem;
  }
}
