.offer-img-container {
  background-size: 100vw 100vh;
  background: linear-gradient(
                  45deg,
                  rgba(0, 0, 0, 0.50) 0%,
                  rgba(10, 10, 10, 0.50) 15%,
                  rgba(20, 20, 20, 0.50) 33%,
                  rgba(30, 30, 30, 0.50) 50%,
                  rgba(40, 40, 40, 0.50) 60%,
                  rgba(50, 50, 50, 0.50) 75%,
                  rgba(60, 60, 60, 0.50) 90%), url("../img/spin2.jpg") no-repeat fixed center;
  background-size: cover;

  @media (max-width: 768px) {
    height: 75vh;
    @supports (-webkit-touch-callout: none) {
      background-attachment: initial;
    }
  }
}

.offer-content {
  min-height: 75%;
  justify-content: space-evenly;
  text-shadow: 2px 1px 2px rgba(10, 10, 10, 0.7);
  font-weight: 100;
  letter-spacing: 2px;

  @media (max-width: 768px) {
    min-height: 100%;
    justify-content: space-between;
  }

  img {
    opacity: 0.33;
  }

  .offer-content-text {
    margin-left: 3px;

    @media (max-width: 768px) {
      font-weight: 400;
    }
  }

  .offer-content-container {
    flex-direction: column;
  }
}

.offer-mobile-content {
  max-width: 95%;
}