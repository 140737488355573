$animate: all 0.3s ease-in-out;

.Nav {
  transition: $animate;
  background: rgba(250, 250, 250, 1);
  height: 130px;

  @media (max-width: 768px) {
    height: 100px;
  }

  nav {
    display: flex;
    margin: 0 auto;
  }

  //outline animation
  .nav-link {
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .nav-link-container {
      display: flex;
      position: relative;
      text-decoration: none;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 1.1rem;
      //letter-spacing: 4px;
      font-weight: 100;
      text-shadow: 2px 1px 2px rgba(10, 10, 10, 0.7);
    }
  }

  .nav-link-container:hover {
    color: rgba(10, 10, 10, 0.7);
  }

  .nav-link-container::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.1rem;
    bottom: -0.1rem;
    left: 0;
    background-color: rgba(10, 10, 10, 0.7);
    box-shadow: 2px 1px 2px rgba(10, 10, 10, 0.7);
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }

  .nav-link-container:hover::before {
    visibility: visible;
    transform: scaleX(1);
  }
}

.nav-container {
  display: flex;
  height: 7rem;
  padding: 1rem 1rem 0 1rem;
  flex-direction: row;
  justify-content: space-between;
}

.nav-logo img {
  max-width: 100px;
  max-height: 100px;

  @media (min-width: 769px) {
    padding-top: 0.5rem;
  }
}

.nav-links {
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
}

.burger-menu {
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.mobile-nav-elements-container {
  position: fixed;
  top: 100px;
  left: 0;
  height: 360px;
  background: #eeece9;
  width: 100%;

  .mobile-nav-elements {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;

    .nav-link {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: 768px) {
        padding: 0 12px;
        height: 60px;
      }

      .nav-link-container {
        height: 100%;
        display: flex;

        a {
          height: 100%;
          width: 100%;
          padding-top: 22px;
        }
      }
    }
  }
}


.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
