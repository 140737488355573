.steps-img-container {
  background-size: 100vw 100vh;
  background: linear-gradient(
                  45deg,
                  rgba(0, 0, 0, 0.50) 0%,
                  rgba(10, 10, 10, 0.50) 15%,
                  rgba(20, 20, 20, 0.50) 33%,
                  rgba(30, 30, 30, 0.50) 50%,
                  rgba(40, 40, 40, 0.50) 60%,
                  rgba(50, 50, 50, 0.50) 75%,
                  rgba(60, 60, 60, 0.50) 90%), url("../img/spin1.jpg") no-repeat fixed center;
  background-size: cover;

  @media (max-width: 768px) {
    @supports (-webkit-touch-callout: none) {
      background-attachment: initial;
    }
  }
}

.steps-content-wrapper {
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
}

.steps-mobile-content {
  max-width: 97%;
}

.steps-background {
  @media (max-width: 768px) {
    height: 150vh;
  }

  @media (max-width: 360px) {
    height: 220vh;
  }
}