.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: linear-gradient(
                  45deg,
                  rgba(0, 0, 0, 0.80) 0%,
                  rgba(10, 10, 10, 0.50) 15%,
                  rgba(20, 20, 20, 0.50) 33%,
                  rgba(30, 30, 30, 0.50) 50%,
                  rgba(40, 40, 40, 0.50) 60%,
                  rgba(50, 50, 50, 0.50) 75%,
                  rgba(60, 60, 60, 0.50) 90%),
  url("components/img/spin2.jpg"), no-repeat, fixed;
  background-size: 100% 100%;
  position: relative;

  @media (max-width: 768px) {
    min-width: 200vw !important;
    overflow: hidden;
    animation: custom-slide 25s infinite alternate;
  }
}


.container2 {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: linear-gradient(
                  45deg,
                  rgba(0, 0, 0, 0.80) 0%,
                  rgba(10, 10, 10, 0.50) 15%,
                  rgba(20, 20, 20, 0.50) 33%,
                  rgba(30, 30, 30, 0.50) 50%,
                  rgba(40, 40, 40, 0.50) 60%,
                  rgba(50, 50, 50, 0.50) 75%,
                  rgba(60, 60, 60, 0.50) 90%),
  url("components/img/spin1.jpg"), no-repeat, fixed;
  background-size: 100% 100%;
  position: relative;

  @media (max-width: 768px) {
    min-width: 200vw;
    overflow: hidden;
    animation: custom-slide 25s infinite alternate;
  }
}

.container3 {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: linear-gradient(
                  45deg,
                  rgba(0, 0, 0, 0.80) 0%,
                  rgba(10, 10, 10, 0.50) 15%,
                  rgba(20, 20, 20, 0.50) 33%,
                  rgba(30, 30, 30, 0.50) 50%,
                  rgba(40, 40, 40, 0.50) 60%,
                  rgba(50, 50, 50, 0.50) 75%,
                  rgba(60, 60, 60, 0.50) 90%),
  url("components/img/spin3.jpg"), no-repeat, fixed;
  background-size: 100% 100%;
  position: relative;

  @media (max-width: 768px) {
    min-width: 200vw;
    overflow: hidden;
    animation: custom-slide 25s infinite alternate;
  }
}

@keyframes custom-slide {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-249%);
    transform: translateX(-249%);
  }
}