
.text-container {
  display: flex;
  flex-direction: column;
  //margin: 0 auto;
  height: 100%;
  justify-content: center;
  font-family: "Nunito Sans", sans-serif;

  strong {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -0.125rem;
      left: -0.5rem;
      right: -0.5rem;
      height: 0.75rem;
      z-index: -1;
      background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/664131/underline.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  h1 > strong {
    font-weight: 400;
  }

  p > strong {
    font-weight: 400;

    &::after {
      bottom: -0.2rem;
      height: 0.5rem;
      left: -0.25rem;
      right: -0.25rem;
    }
  }

  p {
    letter-spacing: 1.5px;
  }

  h1 {
    font-weight: 200;
    font-size: 2.5rem;
  }

  h2 {
    font-weight: 200;
    letter-spacing: 2px;
    text-shadow: 2px 2px rgba(20, 20, 20, 0.6);

  }

  .heading--stroke {
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #0d1b1e;
    color: transparent;
  }

  .heading--shadow {
    text-shadow: 4px 4px #bbbbbb;
  }
}

.text-container-paragraph {
  display: flex;
  flex-direction: row;
}

.one-eight-screen {
  width: 100%;
  height: 12.5vh;
}

.one-fourth-screen {
  width: 100%;
  height: 25vh;
}

.half-screen {
  width: 100%;
  height: 50vh;
}

.three-quarters-screen {
  width: 100%;
  height: 75vh;
}

.full-screen {
  width: 100%;
  height: 100vh;
}

.text-container-back {
  background: #eeece9;
  z-index: -2;
}

.margin-top-xl {
  margin-top: 3rem;
}

.margin-top-xxl {
  margin-top: 6rem;
}

.margin-top-xxxl {
  margin-top: 12rem;
}

.margin-bottom-l {
  margin-bottom: 1.5rem;
}

.margin-bottom-xl {
  margin-bottom: 3rem;
}

.mobile-margin-bottom-xl {
  @media (max-width: 768px) {
    margin-bottom: 3rem;
  }
}

.margin-bottom-xxl {
  margin-bottom: 6rem;
}

.margin-auto {
  margin: auto;
}

.margin-vertical-auto {
  margin: 0 auto;
}

.flex-container-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.align-self-center {
  align-self: center;
}

.svg-size-l {
  font-size: 40px;
}

.app-green {
  color: #00A568;
}

.app-red {
  color: #D44638
}

.app-blue {
  color: #4267B2
}

.shadowed-text {
  text-shadow: 2px 1px 2px rgba(10, 10, 10, 0.7);
  font-weight: 100;
}

.fixed-top-left {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.screen-whole {
  min-width: 100vw;
  min-height: 100vh;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.padding-top-s {
  padding-top: 10px;
}

.padding-bottom-m {
  padding-bottom: 20px;
}

.padding-right-s {
  padding-right: 10px;
}

.padding-left-s {
  padding-left: 10px;
}

.padding-right-m {
  padding-right: 10px;
}

.padding-m {
  padding: 50px 90px;
}

.padding-l {
  padding: 9% 15%;
}

.padding-horizontal-xl {
  padding-right: 80px;
  padding-left: 80px;
}

.padding-horizontal-xs {
  padding-right: 10px;
  padding-left: 10px;
}

.font-lato {
  font-family: 'Lato', sans-serif;
}

.font-size-xxxl {
  font-size: 5rem;
}

.font-size-xxl {
  font-size: 3rem;
}

.font-size-xl {
  font-size: 1.4rem;
}

.font-size-l {
  font-size: 20px;
}

.font-size-m {
  font-size: 14px;
}

.font-size-s {
  font-size: 12px;
}

.font-bold {
  font-weight: bold;
}

.text-align-center {
  text-align: center;
}

.text-align-justify {
  text-align: justify;
}

.align-baseline {
  align-items: baseline;
}

.letter-spacing-s {
  letter-spacing: 1px;
}

.text-no-shadow {
  text-shadow: none !important;
}

.custom-border {
  border: 0.66rem solid rgba(30, 30, 30, 0.8);
  box-shadow: 5px 3px 5px rgba(10, 10, 10, 0.7);
}

.title {
  font-family: "Nunito Sans", sans-serif;
  color: black !important;
}

.height-l {
  min-height: 200px;
}

.text-shadow-s {
  text-shadow: 1px 1px 2px rgba(10, 10, 10, 0.7);
}

.one-whole {
  width: 100%;
}

.one-third {
  width: 33.3%;
}
.full-width {
  width: 100%
}
.sixty-percent-width {
  width: 60%;
}

.one-half-height {
  height: 50%;
}

.one-whole-height {
  height: 100%;
}

.items-center {
  align-items: center;
}

.content-center {
  justify-content: center;
}

.content-evenly {
  justify-content: space-evenly;
}

.cursor-pointer {
  :hover {
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .mobile-hide {
    display: none !important;
  }

  .mobile-one-whole-height {
    height: 100vh !important;
  }

  .mobile-one-and-half {
    height: 150vh;
  }

  .mobile-two-heights {
    height: 200vh !important;
  }
}

@media (min-width: 769px) {
  .full-hide {
    display: none !important;
  }
}