$borderSize: 0.66rem;

.paragraph-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 60%;
  margin: auto;

  @media (max-width: 768px) {
    max-width: 90% !important;
  }

  @media (min-width:769px) and (max-width:1280px) {
    max-width: 75%;
  }

  .paragraph-wrapper-border {
    //border: $borderSize solid rgba(30, 30, 30, 0.8);
    border: $borderSize solid black !important;
    box-shadow: 5px 3px 5px rgba(10, 10, 10, 0.7);
  }

  .paragraph-wrapper-content {
    max-height: 80%;
    min-height: 50%;
    margin-bottom: auto;

    @media (max-width: 768px) {
      max-height: 90%;
      min-height: 80%;
    }
  }

  h3 {
    margin: 0 0 0 $borderSize;
    text-shadow: 2px 1px 2px rgba(10, 10, 10, 0.7);
    font-weight: 100;
    letter-spacing: 2px;
    @media (max-width: 768px) {
      font-size: 3rem;
    }
    font-size: 5rem;
  }

  .paragraph-wrapper-text {
    font-family: "Nunito Sans", sans-serif;
    color: black !important;
  }
  .paragraph-wrapper-header {
    display: flex;
    margin: auto 0 -10px 0;
  }
}