.Projects {

  .projects-row {
    display: flex;
    flex-direction: row;
    height: 50vh;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      height: 200vh;
    }
  }

  .mobile-projects-title {
    //margin-top: 12.5vh;
    margin-left: 20px;
  }

  .project-elem {
    width: 100%;
    height: 100%;

    .project-elem-background {
      width: 100%;
      height: 100%;
    }

    p {
      text-shadow: 1px 1px rgba(20, 20, 20, 0.6);
      opacity: 1;
      transition: 1000ms;
    }

    h2 {
      font-size: 1.5em;
      transition: 300ms;
    }
  }

  .project-elem:hover {
    box-shadow: 0 calc(0.5rem + 1px) calc(0.5rem + 1px) #000;
    opacity: 0.8;
    cursor: pointer;
    transition: ease-in-out 300ms;

    p {
      opacity: 0;
      transition: 300ms;
    }

    h2 {
      font-size: 3em;
      text-shadow: 4px 4px rgba(20, 20, 20, 0.6);
      transition: 300ms;
    }
  }

  #kuchnia {
    background: linear-gradient(
                    45deg,
                    rgba(10,10,10,0.50) 33%,
                    rgba(60,60,60,0.40) 66%), url("./img/kuchnia.jpg"), no-repeat, fixed;
    background-size: 100% 100%;
    position: relative;
  }

  #lazienka {
    background: linear-gradient(
                    45deg,
                    rgba(10,10,10,0.50) 33%,
                    rgba(60,60,60,0.40) 66%), url("./img/lazienka.png"), no-repeat, fixed;
    background-size: 100% 100%;
    position: relative;
  }

  #salon {
    background: linear-gradient(
                    45deg,
                    rgba(10,10,10,0.50) 33%,
                    rgba(60,60,60,0.40) 66%), url("./img/salon.jpg"), no-repeat, fixed;
    background-size: 100% 100%;
    position: relative;
  }

  #sypialnia {
    background: linear-gradient(
                    45deg,
                    rgba(10,10,10,0.50) 33%,
                    rgba(60,60,60,0.40) 66%), url("./img/sypialnia.jpg"), no-repeat, fixed;
    background-size: 100% 100%;
    position: relative;
  }

  #elewacja {
    background: linear-gradient(
                    45deg,
                    rgba(10,10,10,0.50) 33%,
                    rgba(60,60,60,0.40) 66%), url("./img/elewacja.jpg"), no-repeat, fixed;
    background-size: 100% 100%;
    position: relative;
  }

  #rzut {
    background: linear-gradient(
                    45deg,
                    rgba(10,10,10,0.50) 33%,
                    rgba(60,60,60,0.40) 66%), url("./img/rzut.png"), no-repeat, fixed;
    background-size: 100% 100%;
    position: relative;
  }
}