@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Square+Peg&display=swap');

body {
  margin: 0;
  font-family: Bahnschrift, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Montserrat',
    sans-serif, 'Nunito Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(30, 30, 30, 0.8)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:link {
  text-decoration: inherit;
  color: inherit;
}

a:visited {
  text-decoration: inherit;
  color: inherit;
}

html {
  scroll-behavior: smooth !important;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background: white;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: none;
}