.stepElement {
  padding-right: 20px;
  width: 33%;
  //height: 50%;

  @media (max-width: 768px) {
    width: 100%;
    height: 50%;
  }

  .stepElementHeader {
    margin-top: auto;
    margin-bottom: 5px;
  }

  .stepElementContent {
    font-size: 1.1rem;
    text-align: justify;
    p {
      margin: 0;
      @media (max-width: 1320px) and (min-width: 769px) {
        font-size: 10px;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}