$borderSize: 0.66rem;

.image-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: auto;
  padding-top: 1%;

  @media (max-width: 768px) {
    width: 90%;
    padding: 0;
  }

  .image-wrapper-border {
    border: $borderSize solid black;
    box-shadow: 5px 3px 5px rgba(10, 10, 10, 0.7);
  }

  .image-wrapper-content {
    height: 75%;
    margin-bottom: auto;
    padding: 5% 5% 7.5%;
  }

  h3 {
    margin: 0 0 0 0;
    text-shadow: 2px 1px 2px rgba(10, 10, 10, 0.7);
    font-weight: 100;
    letter-spacing: 2px
  }

  .image-wrapper-text {
    font-family: 'Nunito Sans', sans-serif;
    white-space: nowrap;
  }

  .image-wrapper-header {
    min-width: 100% !important;
    display: flex;
    flex-direction: row;
    margin: 5px auto 0;
    align-items: center;

    @media (max-width: 768px) {
      margin: 0 auto;
    }

    @media (max-width: 1320px) and (min-width: 769px) {
      font-size: 12px;
    }

    :nth-child(2) {
      align-self: end;
      //margin: auto 0 0 5px !important;
    }
  }
}