.Content {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-height: 60%;
  max-width: 45%;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  padding: 10% 10% 0;

  @media (max-width: 768px) {
    max-height: 90%;
    max-width: 90%;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    padding: 0;
    margin-top: 20%
  }

}

.half-transparent-background {
  background-color: rgba(255, 255, 255, 0.3);
}



.hero-elem {
  font-family: Ubuntu, sans-serif;
  color: rgba(41,41,41,1);
  width: 47.5%;
  margin-left: 1rem !important;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-left: 0 !important;
    width: 100%;
    justify-content: center;
  }

  p {
    font-weight: 100;
    letter-spacing: 2px;
    line-height: 1.3rem;
    text-shadow: 2px 1px 2px rgba(10, 10, 10, 0.7);
  }

  h1 {
    font-size: 4rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-shadow: 1px 1px 3px rgba(10, 10, 10, 0.7);
    text-align: center;
    font-family: "Square Peg", serif;

    @media (min-width: 769px) {
      //font-size: 8rem;
      white-space: nowrap;
      margin-left: -50px;
    }

    @media (max-width: 1680px) and (min-width: 1321px) {
      font-size: 6rem;
    }
  }

  .greetings-animation {
    animation: 1s ease-out 0s 1 fadeIn;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  img {
    width: 100%;
  }
}