.stepsRow {
  padding-left: 30px;
  height: 50%;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 0;
    padding-left: 10px;
  }
}