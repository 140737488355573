.About {
  display: flex;
  flex-direction: column;

  .about-content {
    @media (max-width: 768px) {
      padding-right: 0;
    }

    @media (max-width: 1320px) and (min-width: 769px) {
      font-size: 12px;
    }
  }

  div {
    font-family: "Nunito Sans", sans-serif;
  }
}

.about-img-container {
  background-size: 100vw 100vh;
  background: linear-gradient(
                  45deg,
                  rgba(0, 0, 0, 0.50) 0%,
                  rgba(10, 10, 10, 0.50) 15%,
                  rgba(20, 20, 20, 0.50) 33%,
                  rgba(30, 30, 30, 0.50) 50%,
                  rgba(40, 40, 40, 0.50) 60%,
                  rgba(50, 50, 50, 0.50) 75%,
                  rgba(60, 60, 60, 0.50) 90%), url("../img/spin3.jpg") no-repeat fixed center;
  background-size: cover;

  @media (max-width: 768px) {
    height: 75vh !important;
    @supports (-webkit-touch-callout: none) {
      background-attachment: initial;
    }
  }
}